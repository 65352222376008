import { gql } from '@/utilities/gql';
import { gqlClient } from '@/utilities/graphQLClient';

import type { QueryFunctionContext } from '@tanstack/react-query';

export const getQueryKey = (portfolioId: string) =>
  ['PORTFOLIO_RECONNECT_LINK_URL', portfolioId] as const;

export const queryFn = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof getQueryKey>>) => {
  const [, portfolioId] = queryKey;
  const response = await gqlClient.request(
    gql(`
      mutation generateReconnectLinkUrl(
        $portfolioId: String!
        $platform: String!
      ) {
        generateReconnectLinkUrl(
          portfolioId: $portfolioId
          platform: $platform
        ) {
          reconnectUrl
          portfolioId
          provider
        }
      }
    `),
    {
      portfolioId,
      platform: RUNTIME_ENV === 'native' ? 'native' : 'browser',
    }
  );

  if ('generateReconnectLinkUrl' in response) {
    return response.generateReconnectLinkUrl;
  }

  return null;
};
