import { Suspense } from 'react';
import { useTracking } from 'react-tracking';
import { ErrorBoundary } from '@sentry/react';

import { Modal, useMount } from '@simplywallst/ui-core';

import type { PortfolioLinkingSDKError } from '../error/PortfolioLinkingSDKError';
import { useGenerateReconnectLinkUrl } from '../hooks/useGenerateReconnectLinkUrl';
import { SharesightPortfolioLinking } from './SharesightPortfolioLinking';

interface Props {
  portfolioId: string;
  onError: (error?: PortfolioLinkingSDKError) => void;
}

const Reconnector = ({ portfolioId }: Pick<Props, 'portfolioId'>) => {
  const { trackEvent } = useTracking({
    type: 'track',
  });

  const { data } = useGenerateReconnectLinkUrl({
    portfolioId,
  });

  useMount(() => {
    trackEvent({ action: 'success' });
  });

  if (data === null) return null;

  return <SharesightPortfolioLinking src={data.reconnectUrl} />;
};

export const SharesightPortfolioReconnector = ({
  portfolioId,
  onError,
}: Props) => {
  return (
    <ErrorBoundary onError={() => onError()}>
      <Suspense fallback={<Modal isNaked isLoading />}>
        <Reconnector portfolioId={portfolioId} />
      </Suspense>
    </ErrorBoundary>
  );
};
