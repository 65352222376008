import { useCallback, useState, useEffect, useRef } from 'react';
import { useTracking } from 'react-tracking';
import GuestRegistrationModal from '@/components/GuestRegistrationModal';
import { NarrativePanelRegister } from '../GuestRegistrationModal/components/NarrativePanelRegister';

export const NarrativeGuestRegistrationModal = () => {
  const trackLimitExceeded = useRef(false);
  const { trackEvent } = useTracking();
  const [showModal, setShowModal] = useState(false);
  const [pixelLimit, setPixelLimit] = useState(window.innerHeight * 0.2);

  const calculateLimit = useCallback(() => {
    setPixelLimit(window.innerHeight * 0.2); // 20% of window height
  }, []);

  const isDismissed = useCallback(() => {
    return sessionStorage.getItem('narrativeModalDismissed') === 'true';
  }, []);

  const handleScroll = useCallback(() => {
    if (window.scrollY > pixelLimit && !isDismissed()) {
      setShowModal(true);
      if (!trackLimitExceeded.current) {
        trackLimitExceeded.current = true;
        trackEvent({
          type: 'track',
          subject: 'GuestRegistrationModal',
          modifier: 'scrollLimit',
          action: 'exceeded',
        });
      }
    } else if (showModal) {
      setShowModal(false);
    }
  }, [pixelLimit, showModal, trackEvent, isDismissed]);

  const onHandleClose = useCallback(() => {
    setShowModal(false);
    sessionStorage.setItem('narrativeModalDismissed', 'true');

    trackEvent({
      type: 'track',
      subject: 'GuestRegistrationModal',
      modifier: 'dismissed',
      action: 'click',
    });
  }, [trackEvent]);

  useEffect(() => {
    calculateLimit(); // Initial calculation
    window.addEventListener('resize', calculateLimit);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', calculateLimit);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [calculateLimit, handleScroll]);

  if (!showModal) {
    return null;
  }

  return (
    <GuestRegistrationModal
      onRegisterSuccess={() => {
        trackEvent({
          type: 'track',
          subject: 'GuestRegistrationModal',
          modifier: 'registration',
          action: 'success',
        });
      }}
      canClose
      onClose={onHandleClose}
      SplashPanel={{
        Component: NarrativePanelRegister,
        isDark: true,
      }}
    />
  );
};
