import { useMount } from '@simplywallst/ui-core';
import { useSnackbar } from '@/features/snackbar';

import { useInitializeProviderConnection } from '../hooks/useInitializeProviderConnection';

import { sentryCaptureMessage } from '@/components/Sentry';
import { useReduxStateForPortfolioLinkingSuccess } from '../hooks/useReduxStateForPortfolioLinkingSuccess';

type Props = {
  onSuccess: () => void;
  onError: () => void;
};

export const SharesightLinkingSuccess = ({ onSuccess, onError }: Props) => {
  const { code } = useReduxStateForPortfolioLinkingSuccess();

  const { addSnackbar } = useSnackbar();

  const { mutate } = useInitializeProviderConnection({
    onError,
    onSuccess,
  });

  useMount(() => {
    if (typeof code === 'undefined') {
      sentryCaptureMessage({
        team: 'wombats',
        message:
          'temporary access token is not defined in PortfolioLinkingSuccess page url',
      });
      onError();
    } else {
      mutate(
        {
          provider: 'sharesight',
          code,
          platform: RUNTIME_ENV === 'native' ? 'native' : 'browser',
        },
        {
          onError: () =>
            addSnackbar({
              type: 'negative',
              message:
                'Failed to save provider token, Portfolio connection failed.',
              lifeTime: 4000,
            }),
        }
      );
    }
  });

  return null;
};
