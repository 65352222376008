import { gql } from '@/utilities/gql';
import { gqlClient } from '@/utilities/graphQLClient';

import type { QueryFunctionContext } from '@tanstack/react-query';

export const getQueryKey = (brokerageId: string) =>
  ['PORTFOLIO_LINK_URL', brokerageId] as const;

export const queryFn = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof getQueryKey>>) => {
  const [, brokerageId] = queryKey;
  const response = await gqlClient.request(
    gql(`
      mutation generatePortfolioLinkUrl(
        $brokerageId: String!
        $platform: String!
      ) {
        generatePortfolioLinkUrl(
          brokerageId: $brokerageId
          platform: $platform
        ) {
          portfolioId
          redirectUrl
          provider
        }
      }
    `),
    {
      brokerageId,
      platform: RUNTIME_ENV === 'native' ? 'native' : 'browser',
    }
  );

  if ('generatePortfolioLinkUrl' in response) {
    return response.generatePortfolioLinkUrl;
  }

  return null;
};
