import { Suspense } from 'react';
import { useTracking } from 'react-tracking';
import { ErrorBoundary } from '@sentry/react';

import { Modal, useMount } from '@simplywallst/ui-core';
import { useSnackbar } from '@/features/snackbar';

import type { PortfolioLinkingSDKError } from '../error/PortfolioLinkingSDKError';
import { useGeneratePortfolioLinkUrl } from '../hooks/useGeneratePortfolioLinkUrl';
import { SharesightPortfolioLinking } from './SharesightPortfolioLinking';

interface Props {
  brokerageId: string;
  onError: (error?: PortfolioLinkingSDKError) => void;
}

const Connector = ({ brokerageId }: Pick<Props, 'brokerageId'>) => {
  const { trackEvent } = useTracking({
    type: 'track',
  });

  const { data } = useGeneratePortfolioLinkUrl({
    brokerageId,
  });

  useMount(() => {
    trackEvent({ action: 'success' });
  });

  if (data === null) return null;

  return (
    <>
      <SharesightPortfolioLinking src={data.redirectUrl} />;
      <Modal isNaked isLoading />
    </>
  );
};

export const SharesightPortfolioConnector = ({
  brokerageId,
  onError,
}: Props) => {
  const { addSnackbar } = useSnackbar();
  return (
    <ErrorBoundary
      onError={() => {
        addSnackbar({
          type: 'negative',
          message: 'Failed to connect',
          lifeTime: 2000,
        });
        onError();
      }}
    >
      <Suspense fallback={<Modal isNaked isLoading />}>
        <Connector brokerageId={brokerageId} />
      </Suspense>
    </ErrorBoundary>
  );
};
