import { useMount } from '@simplywallst/ui-core';

type Props = {
  src: string;
};

export const SharesightPortfolioLinking = ({ src }: Props) => {
  useMount(() => {
    window.location.assign(src);
  });

  return null;
};
