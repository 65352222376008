import { useSuspenseQuery } from '@tanstack/react-query';

import { queryFn, getQueryKey } from './queryFn';

export const useGenerateReconnectLinkUrl = ({
  portfolioId,
}: {
  portfolioId: string;
}) => {
  return useSuspenseQuery({
    queryKey: getQueryKey(portfolioId),
    queryFn,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
};
