import { gql } from '@/utilities/gql';
import { makeGqlMutation } from '@/utilities/gqlQuery';

const MUTATION = gql(
  `
  mutation initializeProviderConnection(
        $code: String!
        $provider: String!
        $platform: String!
      ) {
        initializeProviderConnection(
          code: $code
          platform: $platform
          provider: $provider
        )
      }
`
);

export const useInitializeProviderConnection = makeGqlMutation(MUTATION);
