import { useSelector, shallowEqual } from 'react-redux';

interface LocationState {
  location: {
    payload: {
      provider?: 'sharesight' | 'plaid' | 'passiv';
    };
    query: {
      code?: string; // coming from sharesight
      oauth_state_id?: string; // coming from plaid
    };
  };
}

export function useReduxStateForPortfolioLinkingSuccess() {
  const { provider } = useSelector(
    (state: LocationState) => state.location.payload,
    shallowEqual
  );

  const code = useSelector(
    (state: LocationState) => state.location.query?.code
  );

  const plaidOAuthStateId = useSelector(
    (state: LocationState) => state.location.query?.oauth_state_id
  );

  return { provider, code, plaidOAuthStateId };
}
