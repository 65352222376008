import { useSuspenseQuery } from '@tanstack/react-query';

import { queryFn, getQueryKey } from './queryFn';

export const useGeneratePortfolioLinkUrl = ({
  brokerageId,
}: {
  brokerageId: string;
}) => {
  return useSuspenseQuery({
    queryKey: getQueryKey(brokerageId),
    queryFn,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
};
